@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: "AvenirLTStd-Roman", "Arial","sans-serif";
$boldFontFamily: "AvenirLTStd-Roman", "Arial","sans-serif";
$boldFontWeight: 600;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #1B1B1B;
$linkColor: #0045A2;
$labelColor: #666;
$formColor: #232323;
$utilColor: #0045A2;
$priceColor: #0045A2;
$lightColor: #666;
$btnColorAlpha: #fff;
$btnColorBeta: #fff;
$btnColorStep: #fff;
$titleColor: #232323;
$titleSecondaryColor: #fff;
$subtitleColor: #232323;
$subtitleSecondaryColor: #fff;
$successColor: #2DB253;
$warningColor: #f7941d;
$errorColor: #ff3526;

$bgPrimaryColor: #fff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #0099FF;
$colorBtnAlpha: #fff;
$bgBtnBeta: #0045A2;
$colorBtnBeta: #fff;
$colorBtnOutlineAlpha: #0045A2;
$colorBtnOutlineBeta: #0045A2;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #1b1b1b;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #1b1b1b;
$calculatorTabsTextColor: #e9e9e9;
$calculatorCloseTabs: #444;
$calculatorCloseTabsColor: #666;
$calculatorMainColor: #fff;
$calculatorButton: #0099FF;

$garageBackground: #ffffff;
$garageTextColor: #232323;
$garageCarNoteColor: #fafafa;

$bgTopbarLogin: #232323;
$colorTopbarLogin: #e9e9e9;

$progressBarTitleBG: #0045a2;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #232323;
$progressBarCloseButtonBG: #0099FF;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #1b1b1b;
$paymentCalculatorClosedColor: #e9e9e9;
$paymentCalculatorPanelBG: #1b1b1b;
$paymentCalculatorTermWrapperBG: #e9e9e9;
$paymentCalculatorTermWrapperColor: #e9e9e9;
$paymentCalculatorTermSelectedWrapperBG: #666;
$paymentCalculatorTermSelectedColor: #e9e9e9;
$paymentCalculatorTermBG: #232323;
$paymentCalculatorTermColor: #e9e9e9;

// ========
// Styles
// ========
@include breakpoint(mobile){
    .widget-sr{
        &.makes__subaru{
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }

        }
    }
}
.widget-sr{
    @include clearfix;
    width: 100%;
    &.-hiddenBuyOnline {
        .showroom-vdp-used,
        .showroom-vdp-new {
            .btn-get-started {
                &.vehicle-cta-btn {
                    display: none;
                }
            }
        }
    }
    &.makes__subaru{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .sr-modal__wrapper div.style-specs__modal-close-bloc{
            overflow: auto;
        }
        .sr-topbar-login .btn-login .label{
            font-family: $fontFamily;
        }
        .listing-used-button-loading.sr-button-1 icon{
            margin: 0 !important;
            padding-right: 10px;
         }

        .purchase-methods-cash .purchase-detail-line.price_total{
            border: 0px;
            background-color: #666;
        }    

        .calculatorColumn{
            border-right: 1px solid #666;
        }
        .calculator-tradeIn, .new-customize-payment, .calculator-cash-down, .section-distance{
            border-bottom: 1px solid #666;
        }
        .payment-calculator-wrapper .is-light{
            color: #e9e9e9;
        }
        .sr-paymentCalculator-background .is-util, .sr-paymentCalculator-Panelbackground .is-util{
            color: #0099ff;
        }
        .sr-paymentCalculator__calculatorTerm--background .price span{
            color: #666 !important;
        }
        .sr-paymentCalculator__calculatorTerm--background.selected .price span{
            color: #e9e9e9 !important;
        }
        .sr-paymentCalculator__calculatorTerm--background .rate{
            color: #666;
        }
        .sr-paymentCalculator__calculatorTerm--background.selected .rate{
            color: #e9e9e9;
        }
        .calculator-tradeIn .sr-link{
            color: #0099ff;
        }
        .bloc-options__single--footer.selected .option-choose-cta{
            color: #fff;
        }

        .calculator-done{
            border-top: 1px solid #666;
        }

        .style-color__button.selected{
            border: 3px solid #0099FF;
        }

        .bloc-options__single--footer.selected .option-choose-cta {
            background-color: #0099FF;
            border-color: #0099FF;
        }

        .listing-used-bodystyle.selected.is-price:before {
            color: #0045A2;
        }

        .vehicleBanner-personsViewed{
            background: #666;
            color: #232323;
            opacity: .8;
        }

        .vehicleBanner-photos .vehicleBanner-photo-button{
            background: #666;
            opacity: .7;
        }

        .protection-single--content .protection-cta-selector.selected {
            background-color: #0099FF;
            color: #fff;
            border-color: #0099FF;
        }

        .widget-garage .vehicle-wrapper-info .vehicle-transaction {
            color: #0045A2;
        }

        .widget-sr input[type=radio]:checked+label:after{
            background: #0045A2!important;
        }

        .widget-profile-popupElement form#profileForm [type=checkbox]:checked+label:before, .widget-profile-popupElement form#profileForm input[type=radio]:checked+label:before,
        .widget-sr input[type=radio]:checked+label:before{
            border-color: #0045A2!important;
        }

        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single.selected {
            color: #0045A2;
        }

        .financing-section.tile-payment-info .tile-payment-detail{
            &:last-of-type{
                font-size: 32px;
                font-weight: 700;
            }
        }
        .financing-section.tile-payment-info .tile-payment-detail span{
            font-size: 16px;
            line-height: 20px;
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #00a0dc!important;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#00a0dc 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #00A0DC !important;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border: 1px solid #00A0DC !important;
            .icon,.icon-Crochet{
                color: #00A0DC !important;
            }
        }
        .confirm_deposit--title,
        .confirm_financing--title{
            color: #00A0DC;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #00A0DC;
            }
        }
        .Appointment__content .appointment__steps--single.is-validated {
            .icon-calendar{
                color: #00A0DC;
            }
        }
        .message-handler{
            &.warning,
            &.success,{
                background-color: #00A0DC;
            }
        }
        .has-success-background{
            background-color: #00A0DC !important;
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;
        
        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__subaru{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}